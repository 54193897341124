import React from 'react';
import Masonry from 'react-masonry-css';
import PropTypes from "prop-types";

import Image from '../utilities/image';

const breakpointColumnsObj = {
  default: 4,
  1100: 3,
  700: 2,
  500: 1
};

const PortfolioItems = ({ container = "container mx-auto px-2 md:px-4", images = [] }) => (
  <div className={`${container}`} >
  {
    images.length > 0 && 
    <Masonry
      breakpointCols={breakpointColumnsObj}
      className={`my-masonry-grid`}
      columnClassName="my-masonry-grid_column">
      {images.map((image) => (
        <Image classes="w-full mb-10" key={image.id} alt={image.alt} filename={image.filename} />
      ))}
    </Masonry>
  }
  </div>
);

PortfolioItems.propTypes = {
  container: PropTypes.string,
  images: PropTypes.array,
};

export default PortfolioItems;