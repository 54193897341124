import React from "react";
import PropTypes from "prop-types";
import { Breadcrumb } from 'gatsby-plugin-breadcrumb';

import Layout from "../components/layout";
import SEO from "../components/seo";
import SubpageHero from '../components/subpages/Hero';
import PortfolioItems from '../components/portfolio/PortfolioItems';
import Image from '../components/utilities/image';

const PortfolioPage = ({ pageContext, location }) => {
  const {
    breadcrumb: { crumbs },
  } = pageContext;
  const customCrumbLabel = location.pathname.toLowerCase().replace('-', ' ').replace('/', '');

  return (
    <Layout>
      <SEO
        title="Portfolio"
      />
      <SubpageHero 
        title="Portfolio"
        breadcrumb={
        <Breadcrumb
          crumbs={crumbs}
          crumbSeparator=" | "
          crumbLabel={customCrumbLabel}
        />
        }
      />
      <div className="container mx-auto px-2 md:px-4 mb-8">
        <div className="flex flex-wrap">
          <iframe width="560" height="315" src="https://www.youtube.com/embed/yCnTcS1O7NI" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/nCEP5foH8PE" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
      </div>
      <PortfolioItems 
        images={[
          {
            id: "110",
            alt: "Before & After of work done in west malling",
            filename: "west-malling.jpg"
          },
          {
            id: "100",
            alt: "Maibo Men working on site",
            filename: "workers-laying-down-black-material.jpg"
          },
          {
            id: "102",
            alt: "Maibo worker moving boxes on groudworks",
            filename: "worker-moving-boxes.jpg"
          },
          {
            id: "103",
            alt: "Maibo Workers working on groundwork site",
            filename: "worker-in-high-vis.jpg"
          },
          {
            id: "104",
            alt: "Maibo Digger",
            filename: "maibo-digger.jpg"
          },
          {
            id: "105",
            alt: "Maibo Large building work",
            filename: "large-building-work.jpg"
          },
          {
            id: "1",
            alt: "portfolioItem1",
            filename: "portitem1.jpg"
          },
          {
            id: "2",
            alt: "portfolioItem2",
            filename: "portitem2.jpg"
          },
          {
            id: "22",
            alt: "portfolioItem2",
            filename: "digger.jpg"
          },
          {
            id: "6",
            alt: "portfolioItem1",
            filename: "portitem3.jpg"
          },
          {
            id: "3",
            alt: "portfolioItem3",
            filename: "portitem4.jpg"
          },
          {
            id: "13",
            alt: "portfolioItem3",
            filename: "pool.jpg"
          },
          {
            id: "6",
            alt: "portfolioItem3",
            filename: "portitem5.jpg"
          },
          {
            id: "4",
            alt: "portfolioItem1",
            filename: "portitem6.jpg"
          },
          {
            id: "14",
            alt: "portfolioItem1",
            filename: "digup.jpg"
          },
          {
            id: "5",
            alt: "portfolioItem2",
            filename: "portitem7.jpg"
          },
          {
            id: "8",
            alt: "portfolioItem2",
            filename: "portitem8.jpg"
          },
          {
            id: "18",
            alt: "portfolioItem2",
            filename: "barleylands.jpg"
          },
          {
            id: "9",
            alt: "portfolioItem2",
            filename: "portitem9.jpg"
          },
          {
            id: "10",
            alt: "portfolioItem1",
            filename: "portitem10.jpg"
          },
          {
            id: "11",
            alt: "portfolioItem2",
            filename: "portitem11.jpg"
          },
          {
            id: "7",
            alt: "portfolioItem1",
            filename: "portitem12.jpg"
          },
        ]}
      />
      <div className="container mx-auto px-2 md:px-4 mb-16 md:mb-24">
        <div className="w-full sm:w-2/3">
          <Image filename="home-background.jpg" />
        </div>
      </div>
    </Layout>
  );
}

PortfolioPage.propTypes = {
  pageContext: PropTypes.object,
  location: PropTypes.object,
};

export default PortfolioPage;
